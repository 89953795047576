@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'proximanova';
    src: url('./fonts/ProximaNova/Proxima\ Nova\ Regular.otf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'proximanova';
    src: url('./fonts/ProximaNova/Proxima\ Nova\ Bold.otf');
    font-weight: 700;
    font-style: bold;
}

@font-face {
    font-family: 'proximanova';
    src: url('./fonts/ProximaNova/Proxima\ Nova\ Extrabold.otf');
    font-weight: 800;
    font-style: extra-bold;
}